import React from 'react'

import Logo from '../../components/Logo'
import { TelegramBtn } from '../../components/ClipButton'
import GradBorderContainer from '../../components/GradientBorderBlock'
import VerticalLine from '../../components/VerticalLine'
import SocialButton from './SocialBtn'

import phone_img from './assets/phone.png'
import arrow_icon from './assets/arrow.svg'
import coin_icon from './assets/coin.svg'
import scale_img from './assets/scale.svg'
import hero_built_items from '../../_constants/hero_build_items'
import dc_logo from './assets/socials/dc.svg'
import x_logo from './assets/socials/x.svg'

const Hero = () => {
	return (
		<section className='container '>
			<div className="flex flex-col-reverse relative my_md:flex-row">
				<div className="flex-1 my_md:pt-10 lg:pt-20 flex flex-col items-start justify-start">
					<Logo className='hidden my_md:block relative z-20' />

					<GradBorderContainer gradColor='#FF05FF' mode='half' className="bg-[#0F1327] my_md:bg-dark_blue p-5 my_md:p-10 flex flex-col items-start justify-start gap-4 relative rounded-t-none my_md:rounded-t-[23px] shadow-[24px_8px_50px_2px_rgba(255,_77,_255,_0.24)] my_md:shadow-none -mt-24 my_md:mt-0 lg:mt-10 " classNameContainer={'w-full my_sm:w-auto'} >
						<div className="absolute bottom-[calc(100%-2px)] h-[10px] left-0 w-full">
							<div className="h-10 block my_md:hidden absolute bottom-0 bg-gradient-to-t from-black to-black/0 w-[360px] left-1/2 -translate-x-1/2"/>
							<div
								style={{
									backgroundColor: '#FFF600',
									boxShadow: `0px 0px 16px 0px ${'#FFF600'}`
								}}
								className=" w-[calc(100%-100px)] block my_md:hidden absolute top-[4px] z-20 left-1/2 -translate-x-1/2 h-[2px]"
							/>
							<div
								style={{
									clipPath: 'polygon(0 0, calc(40px) 0, calc(50px) calc(10px), calc(100% - 50px) calc(10px), calc(100% - 40px) 0, 100% 0, 100% 100%, 0 100%)',
								}}
								className="block my_md:hidden h-[10px] bg-[#0F1327] absolute left-0 rounded-t-[25px] bottom-0 w-full"></div>
						</div>

						<h1 className="text-[40px] my_sm:text-[48px] my_md:text-[38px] xl:text-[55px] leading-[128%] font-chakra font-bold text-center my_md:text-left">
							<span className="text-gold">Buy</span> NFT Battle Pass, <br className='hidden my_md:block' /> Secure Airdrop
						</h1>

						<ul className="flex justify-center my_md:justify-start flex-wrap gap-2 mb-4">
							<li className="flex items-center justify-start gap-2">
								<img src={arrow_icon} alt="" className="" />
								<span className="capitalize text-grey text-[18px] my_sm:text-[20px] whitespace-nowrap my_md:whitespace-normal my_md:text-[32px] leading-[150%]">stake pass</span>
							</li>
							<li className="flex items-center justify-start gap-2">
								<img src={arrow_icon} alt="" className="" />
								<span className="capitalize text-grey text-[18px] my_sm:text-[20px] whitespace-nowrap my_md:whitespace-normal my_md:text-[32px] leading-[150%]">mine tokens</span>
							</li>
							<li className="flex items-center justify-start gap-2 my_md:w-full " >
								<img src={arrow_icon} alt="" className="" />
								<span className="capitalize text-grey text-[18px] my_sm:text-[20px] whitespace-nowrap my_md:whitespace-normal my_md:text-[32px] leading-[150%]">get rewards</span>
								<img src={coin_icon} alt="" className="" />
							</li>
						</ul>

						<TelegramBtn className='' containerClassName='w-full my_md:w-auto' />
					</GradBorderContainer>

					<div className="my_md:flex justify-start items-center w-full hidden">
						<VerticalLine className='' color='#FF05FF' />
						<div className="pt-10  w-max pl-0 lg:pl-5 flex justify-between items-center lg:w-full">
							<img src={scale_img} alt="" className="w-[100px] mr-8 lg:mr-0" />

							<div className="flex gap-3 items-center justify-center">
								<span className="text-grey text-[13px] lg:text-[15px] leading-[150%] font-medium font-chakra whitespace-nowrap ">
									Built On
								</span>

								<div className="flex items-center justify-center gap-1 lg:gap-2">
									{
										hero_built_items.map(item =>
											<img key={item.id} src={item.img} alt="" className="" />
										)
									}
								</div>

							</div>

						</div>
					</div>



				</div>

				<div className="flex-1 pt-10 my_md:pt-0">
					<Logo className='block my_md:hidden mx-auto' />

					<img src={phone_img} alt="" className="static w-[calc(100%+100px)] my_md:absolute my_md:w-[calc(50%+50px)] bottom-[100px] lg:static lg:w-[calc(100%+100px)] -mt-8 my_md:mt-0 -ml-10 max-w-none xl:w-auto xl:max-w-full xl:ml-0" />

				</div>
			</div>

			<VerticalLine
				className='h-10 xl:-mt-7 '
				color='linear-gradient(to bottom, #FF05FF 0%, #fff 100%)'
			/>

			<div className="flex items-start justify-start w-full h-[88px]  ">
				<VerticalLine color='#ffffff' className='' />
				<div className="pb-10 max-my_md:ml-5 text-grey font-medium font-chakra">Join the Satoshi Universe community <br className="" />
					and share your impressions</div>
			</div>

			<div className="flex gap-2 my_sm:gap-4 my_md:gap-6 items-center justify-start max-w-full w-[400px] my_md:w-[620px]">
				<SocialButton icon={x_logo} color='#fff' text='@universesatoshi' href='https://twitter.com/universesatoshi'/>
				<SocialButton icon={dc_logo} color='#5865F2' text='@universesatoshi' href='https://discord.gg/satoshiuniverse' />
			</div>

			<VerticalLine
				className='h-40 '
				color='#fff'
			/>


		</section>
	)
}

export default Hero