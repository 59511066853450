import React from 'react'
import cn from '../../utils/cn'

import google_play_img from "./assets/google/google_play_group.svg"
import telegram_icon from './assets/tg_icon.svg'
import apple_store_img from './assets/apple/apple_store_group.svg'
import apple_music_img from './assets/apple/apple_music_group.svg'
import spotify_img from './assets/spotify/spotify_group.svg'
import yt_music_img from './assets/yt/yt_music_group.svg'

interface ClipButtonProps {
	strokeColor?: string
	bgColor?: string
	children?: React.ReactNode
	className?: string
	containerClassName?: string
	onClick?: () => void
}

export const ClipButtonBase = ({
	strokeColor = 'gray',
	bgColor = '#020F1B',
	children,
	className,
	containerClassName,
	onClick
}: ClipButtonProps) => {
	return (
		<button
			style={{
				clipPath: 'polygon(calc(10px) 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(10px))',
				WebkitClipPath: 'polygon(calc(10px) 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(10px))',
				background: strokeColor,
			}}
			className={cn('cursor-pointer bg-red-500 p-[2px]', containerClassName)}
			onClick={onClick}
		>
			<div
				style={{
					clipPath: 'polygon(calc(10px) 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(10px))',
					WebkitClipPath: 'polygon(calc(10px) 0, 100% 0, 100% calc(100% - 10px), calc(100% - 10px) 100%, 0 100%, 0 calc(10px))',
					background: bgColor,
				}}
				className={cn("w-full h-full", className)}
			>
				{children}
			</div>

		</button>
	)
}


export const ClipButtonBaseReverse = ({
	strokeColor = 'gray',
	bgColor = '#020F1B',
	children,
	className,
	containerClassName,
	onClick,
}: ClipButtonProps) => {
	return (
		<button
			style={{
				clipPath: 'polygon(0 0, calc(100% - 10px) 0, 100% calc(10px), 100% 100%, calc(10px) 100%, 0 calc(100% - 10px))',
				WebkitClipPath: 'polygon(0 0, calc(100% - 10px) 0, 100% calc(10px), 100% 100%, calc(10px) 100%, 0 calc(100% - 10px))',
				background: strokeColor,
			}}
			className={cn('cursor-pointer bg-red-500 p-[2px]', containerClassName)}
			onClick={onClick}
		>
			<div
				style={{
					clipPath: 'polygon(0 0, calc(100% - 10px) 0, 100% calc(10px), 100% 100%, calc(10px) 100%, 0 calc(100% - 10px))',
					WebkitClipPath: 'polygon(0 0, calc(100% - 10px) 0, 100% calc(10px), 100% 100%, calc(10px) 100%, 0 calc(100% - 10px))',
					background: bgColor,
				}}
				className={cn("w-full h-full", className)}
			>
				{children}
			</div>

		</button>
	)
}

export const AppDiamondButton = ({ children, bgColor = "#020F1B", className, containerClassName, strokeColor, onClick }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			className={cn('py-3 text-[15px] my_sm:text-base my_sm:py-4 px-8 text-diamond font-bold uppercase font-chakra text-base leading-[125%] h-[50px] my_sm:h-[56px] flex items-center justify-center', className)}
			containerClassName={cn('w-full', containerClassName)}
			bgColor={bgColor}
			strokeColor={strokeColor || '#00FFD3'}
			onClick={() => window.open('https://app.universesatoshi.com/', '_blank')}
		>
			[ {children} ]
		</ClipButtonBase>
	)
}

export const DiamondButton = ({ children, bgColor = "#020F1B", className, containerClassName, strokeColor, onClick }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			className={cn('py-3 text-[15px] my_sm:text-base my_sm:py-4 px-8 text-diamond font-bold uppercase font-chakra text-base leading-[125%] h-[50px] my_sm:h-[56px] flex items-center justify-center', className)}
			containerClassName={cn('w-full', containerClassName)}
			bgColor={bgColor}
			strokeColor={strokeColor || '#00FFD3'}
			onClick={() => window.open('https://t.me/universesatoshi_bot', '_blank')}
		>
			[ {children} ]
		</ClipButtonBase>
	)
}

export const FAQDiamondButton = ({ children, bgColor = "#020F1B", className, containerClassName, strokeColor, onClick }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			className={cn('py-3 text-[15px] my_sm:text-base my_sm:py-4 px-8 text-diamond font-bold uppercase font-chakra text-base leading-[125%] h-[50px] my_sm:h-[56px] flex items-center justify-center', className)}
			containerClassName={cn('w-full', containerClassName)}
			bgColor={bgColor}
			strokeColor={strokeColor || '#00FFD3'}
			onClick={onClick}
		>
			[ {children} ]
		</ClipButtonBase>
	)
}

export const ReadComicsDiamondButton = ({ children, bgColor = "#020F1B", className, containerClassName, strokeColor, onClick }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			className={cn('py-3 text-[15px] my_sm:text-base my_sm:py-4 px-8 text-diamond font-bold uppercase font-chakra text-base leading-[125%] h-[50px] my_sm:h-[56px] flex items-center justify-center', className)}
			containerClassName={cn('w-full', containerClassName)}
			bgColor={bgColor}
			strokeColor={strokeColor || '#00FFD3'}
			onClick={() => window.open('https://www.webtoons.com/en/canvas/satoshi-universe-comics/list?title_no=902918', '_blank')}
		>
			[ {children} ]
		</ClipButtonBase>
	)
}

export const LoreBookDiamondButton = ({ children, bgColor = "#020F1B", className, containerClassName, strokeColor, onClick }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			className={cn('py-3 text-[15px] my_sm:text-base my_sm:py-4 px-8 text-diamond font-bold uppercase font-chakra text-base leading-[125%] h-[50px] my_sm:h-[56px] flex items-center justify-center', className)}
			containerClassName={cn('w-full', containerClassName)}
			bgColor={bgColor}
			strokeColor={strokeColor || '#00FFD3'}
			onClick={() => window.open('https://satoshi-universe.gitbook.io/satoshi-universe-lore', '_blank')}
		>
			[ {children} ]
		</ClipButtonBase>
	)
}

export const GooglePlayBtn = ({ className = '', containerClassName }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-[10px] px-[10px] h-[56px] ', className)}
			strokeColor='linear-gradient(to right, #02D7E5 0%, #01F077 33%, #F83548 66%, #FFC407 100%)'
			onClick={() => window.open('https://play.google.com/store/apps/details?id=com.CryptomeriaLabs.Runner2060', '_blank')}
		>
			<img src={google_play_img} alt="" className="" />
		</ClipButtonBase>
	)
}

export const HexplanetGooglePlayBtn = ({ className = '', containerClassName }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-[10px] px-[10px] h-[56px] ', className)}
			strokeColor='linear-gradient(to right, #02D7E5 0%, #01F077 33%, #F83548 66%, #FFC407 100%)'
			onClick={() => window.open('https://play.google.com/store/apps/details?id=com.Cryptomerialabs.HEXPlanet', '_blank')}
		>
			<img src={google_play_img} alt="" className="" />
		</ClipButtonBase>
	)
}

export const TelegramBtn = ({ className = '', containerClassName }: ClipButtonProps) => {

	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-4 px-[32px] flex gap-4 h-[56px] items-center justify-center', className)}
			strokeColor='linear-gradient(to bottom, #2AABEE 0%, #229ED9 100%)'
			bgColor='linear-gradient(to bottom, #2AABEE 0%, #229ED9 100%)'
			onClick={() => window.open('https://t.me/universesatoshi_bot', '_blank')}
		>
			<img src={telegram_icon} alt="" className="" />
			<span className="font-roboto font-medium text-white leading-[125%] whitespace-nowrap">Join to Telegram app</span>
		</ClipButtonBase>
	)
}

export const AppleStoreBtn = ({ className = '', containerClassName }: ClipButtonProps) => {
	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-[10px] px-[10px] h-[56px] ', className)}
			strokeColor='#fff'
			onClick={() => window.open('https://apps.apple.com/us/app/runner2060/id6504344906', '_blank')}
		>
			<img src={apple_store_img} alt="" className="" />
		</ClipButtonBase>
	)
}

export const AppleMusicBtn = ({ className = '', containerClassName }: ClipButtonProps) => {
	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-[10px] px-[10px] h-[56px] ', className)}
			strokeColor='linear-gradient(to top, #FA233B 0%, #FB5C74 100%)'
			bgColor='linear-gradient(to right, #02262d 0%, #02101c 100%)'
			onClick={() => window.open('https://music.apple.com/ru/album/asylum-satoshi-universe/1731306270?i=1731306275&at=1000lqjf&ct=bq&ls=1', '_blank')}
		>
			<img src={apple_music_img} alt="" className="mx-auto" />
		</ClipButtonBase>
	)
}

export const SpotifyBtn = ({ className = '', containerClassName }: ClipButtonProps) => {
	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-[10px] px-[10px] h-[56px] ', className)}
			strokeColor='#1ED760'
			bgColor='linear-gradient(to right, #023d3e 0%, #04232f 100%)'
			onClick={() => window.open('https://open.spotify.com/track/7mPx7dWEnoTG7KqsRimeJE?si=83372fe68d83470e&nd=1&dlsi=d5ee40cbebac4e7a', '_blank')}
		>
			<img src={spotify_img} alt="" className="mx-auto" />
		</ClipButtonBase>
	)
}

export const YTMusicBtn = ({ className = '', containerClassName }: ClipButtonProps) => {
	return (
		<ClipButtonBase
			containerClassName={containerClassName}
			className={cn('py-[10px] px-[10px] h-[56px] ', className)}
			strokeColor='#F62B1E'
			bgColor='linear-gradient(to right, #023d3e 0%, #02121d 100%)'
			onClick={() => window.open('https://www.youtube.com/watch?v=fBUKA8UTTHk&list=OLAK5uy_l7eu5_gYwRL_nz2c06l4qpFAuEZdYwydY', '_blank')}
		>
			<img src={yt_music_img} alt="" className="mx-auto" />
		</ClipButtonBase>
	)
}

export default ClipButtonBase